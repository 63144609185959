.purejscarousel {
  overflow: hidden;
}

.purejscarousel-slides-container:before,
.purejscarousel-slides-container:after {
  content: "";
  display: table;
}

.purejscarousel-slides-container:after {
  clear: both;
}

.purejscarousel-slide {
  float: left;
}

.purejscarousel-btn {
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer !important;
  height: 20px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 20px;
}

.purejscarousel-btn-next {
  border-bottom: 8px solid #aaa;
  border-right: 8px solid #aaa;
  right: 5px;
  -moz-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.purejscarousel-btn-prev {
  border-bottom: 8px solid #aaa;
  border-left: 8px solid #aaa;
  left: 5px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.purejscarousel-dots-container {
  float: left;
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

.purejscarousel-dot {
  background: #aaa;
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 15px;
  outline: none;
  margin: 0 5px;
  width: 15px;
  display: none;
}

.active.purejscarousel-dot {
  cursor: default;
  opacity: .5;
}

h1 {
  text-align: center;
}

.carousel {
  margin: 50px auto;
  overflow: hidden;
  position: relative;
  width: 240px;
}

.carousel:before,
.carousel:after {
  content: "";
  display: table;
}

.carousel:after {
  clear: both;
}