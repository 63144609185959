@import 'colors';

$primary: $blue;
$secondary: $blue;
$link-color: $cyan;

// Settings for the `<body>` element.
$body-bg: $gray-100;
$body-color: $gray-700;

$font-family-sans-serif: 'Open Sans', sans-serif;
$text-muted: $gray-700;

$enable-rounded: false;

// Buttons
//
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;

// Modal
//
$modal-content-bg: $gray-100;

// Nav Tabset
//
$nav-tabs-border-color: $blue;
$nav-tabs-border-width: 3px;
$nav-tabs-link-active-border-color: transparent transparent $blue transparent;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-bg: lighten($blue, 55%);

$component-active-bg: $blue !default;

// Pagination
//
// $pagination-color:                  $link-color !default;
// $pagination-bg:                     $white !default;
// $pagination-border-width:           $border-width !default;
// $pagination-border-color:           $gray-300 !default;
// $pagination-hover-color:            $link-hover-color !default;
// $pagination-hover-bg:               $gray-200 !default;
// $pagination-hover-border-color:     $gray-300 !default;
// $pagination-active-color:           $component-active-color !default;
// $pagination-active-bg:              $component-active-bg !default;
// $pagination-active-border-color:    $pagination-active-bg !default;

// Breadcrumbs
//
$breadcrumb-bg: none;
