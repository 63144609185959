@import 'font-awesome/scss/variables';

$fa-font-path: '~font-awesome/fonts';

@import 'font-awesome/scss/mixins';

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: local('FontAwesome Regular'), local('FontAwesome-Regular'),
    url('#{$fa-font-path}/fontawesome-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$fa-font-path}/fontawesome-webfont.woff') format('woff'); /* Modern Browsers */
}

@import 'font-awesome/scss/core';
@import 'font-awesome/scss/larger';
@import 'font-awesome/scss/fixed-width';
@import 'font-awesome/scss/list';
@import 'font-awesome/scss/bordered-pulled';
@import 'font-awesome/scss/animated';
@import 'font-awesome/scss/rotated-flipped';
@import 'font-awesome/scss/stacked';
@import 'font-awesome/scss/icons';
@import 'font-awesome/scss/screen-reader';
