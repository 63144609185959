// NHEASY custom greys
$gray-100: #f2f2f2;
$gray-250: #767676;
$gray-300: #dee2e6;
$gray-700: #132742;
$gray-900: #222c30;
$gray-title-color: #3D3E40;
// NHEASY custom colors
$white: #fff;
$blue: #2274aa;
$orange: #da3a25;
$green: #557E1B;
$indigo: #2654a0;
$red: #dc3545;
$purple: #6f42c1;
$teal: $green;
$yellow: #ffc107;
$cyan: #3072ab;
$black: #000;
$light-blue: #eaf4fb;
$pink: #ff758d;

$progress-bar-blue: #3264B4;
$progress-bar-light-blue: #CDDDF4
