body,
.container {
  scroll-behavior: smooth;
  color: #3D3E40;
  font: normal normal 400 18px 'Open Sans';
}

a,
button,
img,
*[role='button'] {
  &:focus {
    outline: thin dotted !important;
  }
}

// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
//
// In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
// DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
// However, they DO support removing the click delay via `touch-action: manipulation`.
// See:
// * http://caniuse.com/#feat=css-touch-action
// * http://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

small {
  font-size: 75%;
}

label {
  display: unset;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input::placeholder {
  color: $gray-500 !important;
  font-style: italic;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: pre-wrap !important;
}

.ngx-datatable.bootstrap {
  overflow-wrap: break-word;
  font: normal normal normal 16px 'Open Sans';
  color:#3d3e40;

  .datatable-header {
    background: #132742;
    color: #ffffff;
    font: normal normal bold 16px 'Open Sans';
  }
  .datatable-header-cell {
   align-items: center !important;
  }
  .datatable-body .datatable-body-row {
    border: 0px; 
  }
  .datatable-footer {
    background: $white !important;

    .datatable-footer-inner {
      .page-count {
        display: none;
      }

      .datatable-pager {
        text-align: center;

        .pager {

          [aria-label='go to first page'],
          [aria-label='go to previous page'],
          [aria-label='go to next page'],
          [aria-label='go to last page'] {
            background-color: $white;
            color: lighten($orange, 10%);
            border: 1px solid $orange;
            line-height: 20px;
          }

          .datatable-icon-prev,
          .datatable-icon-left,
          .datatable-icon-right,
          .datatable-icon-skip {
            font-weight: bold;
          }

          .datatable-icon-prev {
            @extend .fa;
            @extend .fa-angle-double-left;
          }

          .datatable-icon-left {
            @extend .fa;
            @extend .fa-angle-left;
          }

          .datatable-icon-right {
            @extend .fa;
            @extend .fa-angle-right;
          }

          .datatable-icon-skip {
            @extend .fa;
            @extend .fa-angle-double-right;
          }
          li a {
            background-color: #3d3e40;
            border-radius: 2px;
            font-weight: bold;
          }
          li:not(.disabled).active a,
          li:not(.disabled):hover a {
            background-color: #132742;
          }

          li.disabled a {
            color: $gray-250;
            background-color: $gray-300;
            border: 1px solid $gray-300;
          }
        }
      }
    }
  }

  .datatable-header-cell-label {
    font-weight: 700 !important;
    line-height: 14px !important;
  }
}

.table {
  margin-bottom: 2rem;
  font: 16px 'Open Sans';
  color: #3d3e40 !important;
  tr, td {
    border-top: 0px !important;
    vertical-align: inherit;
  }
  thead {
    font-weight: 700;
    th {
      vertical-align: middle;
    }

    tr {
      background-color: #132742;
      color: #ffffff;
    }
  }


  th {
    border-top: 0;
  }
}

.nh-table-mobile {
  margin-bottom: 2rem;
  .mobile-table-header {
    font: 700 16px 'Open Sans';
    color: #132742;
  }
  .mobile-table-data {
    font: normal normal normal 16px 'Open Sans';
    color: #3d3e40;
  }
  .table-row {
    border-bottom: 1px solid #3D3E40;
  }

  ul {
    margin-bottom: 1rem;

    li:nth-child(even) {
      background: $gray-100;
    }

    li:nth-child(odd) {
      background: $white;
    }

    table {
      margin-bottom: 0;

      tr {
        th {
          border-right: 1px solid $gray-250;
          text-align: right;
        }

        td {
          border: 0;
          text-align: left;
        }
      }
    }
  }
}

// Helper classes
.no-hover {
  text-decoration: none !important;
}

.btn-inherit {
  color: inherit !important;
  border-color: inherit !important;
  background-color: inherit !important;
}

// Bootstrap classes resets
//
// Buttons
//
.btn {
  @include montserrat(600);
  font-size: .7rem !important;
  line-height: 1.8;
  text-transform: uppercase;

  &.btn-outline-primary:focus,
  &.btn-outline-secondary:focus {
    box-shadow: none;
    outline: none !important;
  }
}

@media only screen and (max-width: 439px) {
  nh-section-info button.btn {
    font-size: .5rem !important;
  }
}

.btn-outline-primary {
  background: $white;
}

// Popovers
//
.popover-tooltip {
  background-color: theme-color-level('warning', -10);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  .arrow {
    &::after {
      border-top-color: theme-color-level('warning', -10);
    }
  }
}

@media only screen and (max-width: 439px) {
  .popover{
		left:0px!important;
	}
	.bs-popover-top .arrow::before,
	.popover-tooltip .arrow::after{
		left:-32px!important;
	}
}

@media all and (min-width: 439px) {
  .special-alignment {
    padding-left: 2.6rem !important;
  }
}

.reset-height {
  height: 100vh !important;
  overflow-y: auto !important;
}

.ng-sidebar {
  background: $gray-100;
  height: 100%;
  max-width: 320px;
  padding: 2rem;
  width: calc(100% - 56px);

  &--animate {
    transition: transform 600ms cubic-bezier(0, 0, 0.3, 1) 0ms !important;
  }
}

.modal-content {
  border: none;

  .modal-header {
    background:  $gray-700;
    padding: 1rem 1rem 0.75rem;

    .modal-title {
      color: $white;
      font-size: 1.125rem;
      font-weight: 700;
      letter-spacing: 0.125rem;
    }

    .close {
      margin: -0.5rem;
      opacity: 1;
      padding: 5px;

      &:focus {
        color: $white;
      }

      span {
        background: $white;
        border-radius: 50%;
        color: $blue;
        display: block;
        height: 30px;
        line-height: 1.25;
        width: 30px;
      }
    }
  }

  .modal-body {
    p {
      font-size: 1.1rem !important;
    }
  }

  .modal-footer {
    border-top: 1px solid $gray-300;
  }
}

label {
  font-weight: 600;
}

.readonly .form-control:not([type='checkbox']),
.readonly .custom-select:disabled {
  background: $white;
  border: 0;
  outline: none !important;
}

.form-control:not([type='checkbox']),
.custom-select {

  &:disabled,
  &[readonly] {
    background-color: $white;
    border: 0;
    outline: none !important;

    &:focus {
      outline: none !important;
      border: 0;
      box-shadow: none;
    }

    &::placeholder {
      visibility: hidden;
    }
  }

  &:disabled {
    background-color: #f1f1f2;
    border: 1px solid #d1d3d4;
    font-size: 16px;
    font-family: 'Open Sans';
    color: #3d3e40 !important;
    font-weight: 400;
  }
}

select:disabled {
  background-color: #f1f1f2;
  border: 1px solid #d1d3d4;
  font-size: 16px;
  font-family: 'Open Sans';
  color: #3d3e40 !important;
  font-weight: 400;
}

$colorList: 'success' $success, 'info' $info, 'warning' $warning, 'danger' $danger;

@each $i in $colorList {
  .alert-#{nth($i, 1)}:not(.info-box) {
    border-color: transparentize(nth($i, 2), 0.8);
  }
}

.btn-outline-light {
  border-color: #ced4da;
  color: $gray-700;

  &:hover {
    background: $white;
    border-color: #ced4da;
    color: $gray-700;
  }
}

.modal-lg {
  & .modal-content .modal-header .modal-title {
    font: normal normal 600 36px 'Open Sans';
    letter-spacing: normal;;
    color: #FFFFFF;
    line-height: 1;
  }

  &.warning {
    & .modal-content .modal-header {
      background: $orange;

      .close span {
        color: $orange;
      }
    }
  }

  &.primary {
    & .modal-content .modal-header {
      background: $gray-700;
    }
  }
}

.list-inline {
  list-style: outside;
  padding: 0 10px;
  margin-left: 20px;

  li {
    padding-top: .1em;
    line-height: 1.6;
  }
}

.thick {
  border-right: medium none;
  border-top: #cccccc 1px solid;
  border-left: medium none;
  border-bottom: medium none;
  height: 1px;
}

.list-group {
  &.list-group-flush {
    .list-group-item {
      background-color: transparent;
      padding: .75rem 0;
    }
  }
}

.add-another {
  text-transform: none;
}

.invalid-name {
  position: inherit;
  position: unset;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #d12a00;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.continue-button {
  color: #2274aa;
}

.greyish-panels {
  background: #f2f2f2;
  width: 100%;
  border-radius: 4px;
  margin-left: 10px;
}

.application-count {
  font-size: 76px;
  border-bottom: 1px solid #D1D3D4;
  display: inline-block;
}

@media (max-width: 500px) {
  .application-count {
   border-bottom: 0px;
   width: auto;
  }
}

.action-end {
  position: absolute;
  bottom: 8px;
  padding-bottom: 3px;
}

.action-end .action-button {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

//Changed to meet ADA Requirements
.invalid-tooltip {
  background-color:#d12a00;
}

.alert-dismissible .close {
  color: #000;
}

.form-check-label {
  font: 16px 'Open Sans';
  color: #3D3E40;
  font-weight: 400;
}
.form-check-input {
  margin-top: 0.5rem;
}
