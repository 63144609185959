/* montserrat-300normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Montserrat Light '), local('Montserrat-Light'),
    url('~typeface-montserrat/files/montserrat-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-montserrat/files/montserrat-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-500normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Montserrat Medium '), local('Montserrat-Medium'),
    url('~typeface-montserrat/files/montserrat-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-montserrat/files/montserrat-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-600normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Montserrat SemiBold '), local('Montserrat-SemiBold'),
    url('~typeface-montserrat/files/montserrat-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-montserrat/files/montserrat-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-700normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Montserrat Bold '), local('Montserrat-Bold'),
    url('~typeface-montserrat/files/montserrat-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-montserrat/files/montserrat-latin-700.woff') format('woff'); /* Modern Browsers */
}
