@media print {
  html, body {
    margin: 0;
    padding: 0;
    background-color: $white;
  }

  h1, h2, h3, h4, h5, div {
    width: 100% !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
    -ms-overflow-style: none !important;
  }

  nh-spinner,
  ng-sidebar,
  nh-header,
  nh-notifications,
  nh-page-title,
  nh-stepper .stepper-container,
  nh-feedback,
  nh-tabset,
  nh-button-bar,
  nh-footer {
    display: none !important;
  }

  .sections {
    border: none !important;
  }

  .reset-height,
  .ng-sidebar__content {
    overflow: visible !important;
  }
}
