// Fonts
@import 'fonts';

// Bootstrap custom vars
@import 'custom';
@import 'bootstrap';

@import 'print';

// HTML and Bootstrap classes resets
@import 'resets';
@import 'app/scss/breakpoints';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.hachs,
.demographics {
  label[for=null] {
    display: none;
  }
}

.small-font {
  font-size: 0.9em;
}

.validation-feedback {
  width: 100%;
  font-size: 80%;
  margin-top: 0.25em;
  color: #dc3545
}

.read-only {

  input,
  button {
    background-color: #f9f9f9;
    pointer-events: none;
    cursor: default;
    outline: none;
  }

  .input-group-append {
    background-color: #f9f9f9;
    max-width: 340px;

    button {
      i {
        color: #666 !important;
      }
    }
  }
}

.input-group {
  max-width: 340px;
  background-color: #fff;
}

.report-section button.sections {
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
  border-width: 2px !important;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: calc(100% - 30px);
    display: block;
  }

  .section-column {
    display: inline-block;
    text-align: left;
  }

  h6 {
    display: inline-block;
    padding-left: 25px;
    text-align: left;
    width: 100%;

    strong {
      color: #2274aa;
    }
  }

  img {
    width: 80px;
    float: left;
  }

  .householdTooltip img {
    width: 18.75px;
  }

  .description {
    font: normal normal 400 16px 'Open Sans';
    display: inline-block;
    text-align: left;
    color: #3D3E40;

    @media (max-width: 767px) {
      text-align: center !important;
    }
  }

  &:hover,
  &.selected,
  &:disabled {
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, .175) !important;
  }

  &.selected,
  &:disabled {
    border-color: #b9bcc0 !important;
    background-color: #fff !important;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

@media screen and (min-width: 768px) {
  .report-grid {
    .report-section button.sections {
      height: calc(100% - 30px);
    }

    .report-section:nth-child(odd) button.sections {
      margin-right: 0 !important;
    }

    // .report-section:nth-child(even) button.sections {
    //   margin-left: 0 !important;
    // }
  }
}

.health-insurance input {
  &[disabled=disabled] {
    visibility: visible !important;
  }
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #ADFF2F !important;
}

// Gateway Styles

.bg-custom {
  background: #FFF;
  border-top: 1px solid #bfbfbf;
  padding: 10px;
}

h1.page-title {
  margin-bottom: 20px;
}

.page-title {
  color: #132742;
  border-bottom: 1px solid #D1D3D4;
  font: normal normal bold 44px/53px PermianSlabSerifTypeface;
  padding-bottom: 4px;
  text-align: left;
  margin-top: 32px;
}

@media screen and (max-width: 767px) {
  .page-title {
    margin-top: 20px;
    font-size: 36px;
  }
}


.section-header {
  color: #132742;
  font: normal normal bold 24px PermianSlabSerifTypeface;
  text-align: left;
  padding-top: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  padding-left: 4px;
}



.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;

  background-color: rgba(255, 243, 205, 1);

  color: black;

  text-align: center;

  border: 1px solid rgba(0, 0, 0, 0.2);

  padding: 5px 0;

  position: absolute;

  z-index: 1060;

  bottom: 105%;

  left: -347%;

  margin-left: -60px;

  font-style: normal;

  font-size: 0.875rem;

  font-weight: 400;

  font-family: "Open Sans", sans-serif;

  line-height: 1.5;

  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  padding: 0.5rem 0.75rem;

  text-align: left;

}




$border-width: 10px;

.tooltip .tooltiptext::before,

.tooltip .tooltiptext::after {

  content: " ";

  position: absolute;

  border-left: $border-width solid transparent;

  border-right: $border-width solid transparent;

  top: 100%;

  left: 50%;

  margin-left: -$border-width;

}




.tooltip .tooltiptext::before {

  border-top: 9px solid rgba(0, 0, 0, 0.2);

}






.tooltip .tooltiptext::after {

  border-top: $border-width solid rgba(255, 243, 205, 1);

  margin-top: -2px;

  z-index: 1;

  border-left: $border-width 1px solid black;

  border-right: $border-width 1px solid black;

}



.tooltip:hover .tooltiptext {

  visibility: visible;

}




@media screen and (max-width: 415px) {

  .tooltip .tooltiptext {

    margin-left: 2px;

  }

  .tooltip .tooltiptext::before,

  .tooltip .tooltiptext::after {

    left: 29%;

  }

}

@media screen and (max-width: 415px) {
  .section-header {
    padding-top: 25px;
  }

  button#continue.btn.btn-block.btn-primary,
  button.btn.btn-primary {
    margin-left: 1px !important;
  }
}

.financial-header {
  color: #132742;
  font: normal normal bold 24px PermianSlabSerifTypeface;
  text-align: left;
  padding-top: 5px;
}

.person-label {
  color: #132742;
  font-size: 18px;
  font-family: 'Open Sans';
  font-weight: 700;
  text-align: left;
  padding-top: 5px;
}


.static-text {
  color: #3D3E40;
  font: normal normal normal 18px/27px Open Sans;
  text-align: left;
  letter-spacing: 0px;
}

.page-title-small {
  color: $blue;
  font-weight: 500;
}

span.tab-section {
  font: normal normal bold 16px/24px Open Sans;
  align-self: center;
  letter-spacing: 0px;

  &:active {
    color: #174A7C !important;
  }

  &:disabled {
    color: #3D3E40 !important;
  }
}

@media screen and (max-width: 320px) {
  span.tab-section {
    font: normal normal bold 13px/24px Open Sans;
  }
}

.label-section {
  color: #132742;
  font: normal normal bold 18px/27px Open Sans;
  text-align: left;
  letter-spacing: 0px;
}

.hint {
  color: #3D3E40;
  font: normal normal normal 16px/24px Open Sans;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.custom-select-info {
  border: 1px solid #D1D3D4;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
  max-width: 340px;
  display: block;
}

.btn.btn-outline-light {
  background-image: url("/assets/images/calendar-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
}

input.form-control {
  max-width: 340px;
  display: block;
  border: 1px solid #D1D3D4;
  border-radius: 4px;
  background-color: #FFFFFF;
}

button#continue.btn-secondary,
button#back.btn-secondary,
button.btn.btn-secondary.btn-block,
button.btn.btn-secondary,
button.btn.btn-secondary-sub.btn-block {
  min-width: 151px;
  height: 48px;
  border-radius: 4px;
  font: normal normal bold 18px/27px Open Sans !important;
  color: #fff;
  background: #174a7c;

  &:hover {
    background: #d12a00;
    border: 1px solid #d12a00;
  }

  &:active {
    background: #132742 !important;
    color: #fff !important;
  }

  &:disabled {
    background: #d1d3d4 !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}

@media screen and (max-width: 767px) {

  button#continue.btn-secondary,
  button#back.btn-secondary,
  button#myAppeals.btn-primary,
  .modal-footer .btn {
    min-width: 328px !important;
    height: 48px;
    background: #174a7c;
    font: normal normal bold 18px/27px Open Sans !important;
    border-radius: 4px;
    margin-bottom: 10px;

    &:active {
      background: #132742 !important;
    }

    &:disabled {
      background: #d1d3d4 !important;
      color: #3d3e40 !important;
      border-color: #d1d3d4 !important;
    }
  }

  .modal-footer .btn.btn-block,
  .modal-footer button[type=button].btn.btn-link {
    width: 100% !important;
  }

  .modal-footer {
    justify-content: center !important;
    padding: 0 !important;
  }

  .modal-footer .form-row {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  button.btn.btn-secondary-sub.btn-block {
    margin-left: 1rem;
    min-width: 275px;
    height: 48px;
    background: #174a7c;
    font: normal normal bold 18px/27px Open Sans !important;
    border-radius: 4px;
    margin-bottom: 10px;

    &:active {
      background: #132742 !important;
    }

    &:disabled {
      background: #d1d3d4 !important;
      color: #3d3e40 !important;
      border-color: #d1d3d4 !important;
    }
  }
}

button#continue.btn.btn-block.btn-primary,
button.btn.btn-primary {
  width: 148px !important;
  height: 48px;
  border-radius: 4px;
  font: normal normal bold 18px/27px Open Sans !important;
  color: #fff;
  background: #174a7c;
  margin-left: 17px;

  &:hover {
    background: #d12a00;
  }

  &:active {
    background: #132742 !important;
    color: #fff !important;
  }

  &:disabled {
    background: #fff !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}

@media screen and (max-width: 767px) {
  button#continue.btn.btn-block.btn-primary {
    width: 328px;
    height: 48px;
    background: #174a7c;

    &:active {
      background: #132742 !important;
    }

    &:hover {
      background: #132742 !important;
    }
  }
}

button#coverageDetailsMob.btn.btn-block.btn-outline-secondary {
  width: 148px !important;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #132742;
  color: #132742;
  background: #fff;
  font: normal normal bold 18px/15px Open Sans !important;

  &:hover {
    background: #d12a00;
    border: 1px solid #d12a00;
    color: #fff;
  }

  &:active {
    background: #fff !important;
    color: #132742 !important;
  }

  &:disabled {
    background: #fff !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}

button#saveExit.btn.btn-block.btn-outline-secondary {
  width: 148px !important;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #132742;
  color: #132742;
  background: #fff;
  font: normal normal bold 18px/15px Open Sans !important;

  &:hover {
    background: #d12a00;
    border: 1px solid #d12a00;
    color: #fff;
  }

  &:active {
    background: #fff !important;
    color: #132742 !important;
  }

  &:disabled {
    background: #fff !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}


button#btn-cancel.btn.btn-block.btn-outline-secondary {
  width: 148px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #132742;
  color: #132742;
  background: #fff;
  font: normal normal bold 18px/27px Open Sans !important;

  &:hover {
    background: #d12a00;
    border: 1px solid #d12a00;
    color: #fff;
  }

  &:active {
    background: #fff !important;
    color: #132742 !important;
  }

  &:disabled {
    background: #fff !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}

@media screen and (max-width: 767px) {

  button#saveExit.btn.btn-block.btn-outline-secondary,
  button[type=button].btn.btn-link {
    width: 328px !important;
    height: 48px;
    background: #fff;
    margin-left: 1px;

    &:active {
      background: #fff !important;
      border-color: #174a7c !important;
    }

    &:hover {
      background: #fff !important;
      border-color: #132742 !important;
    }
  }

  button#coverageDetailsMob.btn.btn-block.btn-outline-secondary,
  button[type=button].btn.btn-link {
    width: 278px !important;
    height: 48px;
    background: #fff;
    margin-left: 1px;

    &:active {
      background: #fff !important;
      border-color: #174a7c !important;
    }

    &:hover {
      background: #fff !important;
      border-color: #132742 !important;
    }
  }

  button#btn-cancel.btn.btn-block.btn-outline-secondary {
    width: 328px !important;
    height: 48px;
    border-radius: 4px;
    font: normal normal bold 18px/27px Open Sans !important;
    background: #fff;
    margin-left: 1px;

    &:active {
      background: #fff !important;
      border-color: #174a7c !important;
    }
  }
}

button[type=button].btn.btn-link,
button.btn.btn-outline-primary.btn-block,
button.ml-4.mr-3.btn.btn-outline-secondary,
button.btn.btn-secondary.btn-sm {
  min-width: 148px;
  min-height: 48px;
  border-radius: 4px;
  border: 1px solid #132742;
  color: #132742;
  background: #fff;
  font: normal normal bold 18px/27px Open Sans !important;

  &:hover {
    background: #d12a00;
    color: #fff;
    border: 1px solid #d12a00;
  }

  &:active {
    background: #fff !important;
    color: #132742 !important;
  }

  &:disabled {
    background: #d1d3d4 !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}

// Before start styles

.important-info {
  height: 150px;
  overflow-y: scroll;
  font-size: 12px;
  border: 1px solid #bfbfbf;
  padding: 4px 14px;
  margin: 18px 0;
  background-color: #f2f2f2;
}

.multiselect-dropdown .dropdown-list {
  font: normal normal normal 16px/24px Open Sans;
}

.multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] {
  border: 2px solid #A9A7AC;
}

.multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked+div:after {
  background: white !important;
  border-top-color: white;
  ;
  border: 2px solid #A9A7AC;
}

.list-content ul {
  padding-left: 10px;

  li {
    list-style: inside;
  }
}

input[type=checkbox] {
  transform: scale(1.5);
}

.rtBox {
  height: 180px;
  overflow: scroll;
  border: 1px solid lightgray;
}

.assistTable {
  text-align: left !important;

  thead {
    tr {
      background-color: $gray-700;

      th {
        color: white;
      }
    }
  }
}

.ktbecketta-address-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  background: #F1F1F2;
}

.ktbecketta-address-card-selected {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  cursor: pointer;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
}

.ktbecketta-address-card:active {
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.6);
}


li.password-rules {
  list-style-type: none;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

li.password-rules-valid {
  color: #547e1a;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.password-guide {
  border: 1px solid #d1d3d4;
  padding: 1.5rem;
  border-top: 1px solid #3264B4;

}

.password-guide-style {
  margin-right: 0px;
  margin-left: 0px;
  flex-wrap: nowrap;

  div {
    padding-left: 10px;
  }
}


.bg-custom-register {
  background: #FFF;
  border-top: 1px solid #bfbfbf;
  padding: 20px;
}

.mobile-progress-bar {
  position: absolute;
  z-index: 2;
  background-color: white;
}

.sub-text-prog {
  font-size: 14px;
  font-weight: bold;
  color: #3d3e40;
  padding-top: 5px;
}

.app-tracker {
  font-weight: bold;
  color: #132742;
  font-size: 20px;
}

.applyColor {
  background: #e3f2fd;
}

.normal-p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #132742;
  text-align: left;
}

.hover-red {
  color: #174a7c;

  .btn.btn-link {
    text-decoration: none;
    text-transform: none;
    font: normal normal bold 18px/27px Open Sans !important;
  }

  span {
    font-weight: 600 !important;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  svg path,
  svg g path {
    fill: #174a7c !important;
  }

  :disabled {
    color: #6c757d;

    svg path {
      fill: #6c757d !important;
    }
  }

  :hover {
    i {
      color: #d12a00;
    }

    span {
      color: #d12a00;
      text-decoration: underline;
    }

    svg path,
    svg g path {
      fill: #d12a00 !important;
    }
  }

}

.add-another-left {
  text-align: left;
}


.password-eye {
  display: flex;
}

.password-eye .eye-icon {
  margin-left: -30px;
  padding-top: 4px;
}

p {
  color: #3D3E40;
  font: normal normal 400 18px 'Open Sans';
}

.modal-backdrop {
  background: #3D3E40;

  .show {
    opacity: 0.8;
  }
}

.modal-content {
  background: #ffffff;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 753px;
  }
}

@media (max-width: 767px) {

  .modal-lg,
  .modal-xl {
    max-width: 343px;

    & .modal-content .modal-header .modal-title {
      font-size: 28px;
    }
  }
}

@media (max-device-width: 812px) {
  .button-box-link {
    margin-left: 15px;
  }

  .button-box2 {
    margin-left: 25px;
  }
}

.pp-tables {
  color: white !important;
}

.password-header {
  color: #132742;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
}

.details-container {
  font: 16px 'Open Sans';

  .bg-details-container {
    background-color: #132742;
    color: #ffffff;
    font-weight: 700;
  }

  .bg-even:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  label {
    font: 700 16px 'Open Sans';
  }

  .form-row {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.modal-table {
  td:first-child {
    width: 142px;
    max-width: 142px;
  }

  td:last-child {
    width: 563px;
    max-width: 563px;
  }
}

@media (max-width: 500px) {

  input.form-control,
  .custom-select-info,
  .input-group,
  .input-group-append {
    min-width: 30px;
  }
}

.helpful-tips {
  border-top: 4px solid #7f9ab9 !important;
  border: 1px solid lightgray;

  .sub-section {
    color: #132742;
    font: normal normal bold 18px Open Sans;
    font-weight: 700;
    text-align: left;
  }
}

.start-page ul {
  list-style-type: disc;
}

.error-messages {
  max-width: 340px;
}

@media (max-width: 500px) {
  .error-messages {
    min-width: 310px;
  }

  .modal-body {
    .error-messages {
      min-width: auto;
    }
  }
}

input::-ms-reveal {
  display: none;
}

.tile-text {
  color: #3D3E40 !important;
  font: normal normal 400 16px 'Open Sans';
}

.tile_header {
  font: normal normal 700 18px 'Open Sans';
  color: #174a7c !important;

  @media (max-width: 767px) {
    text-align: center !important;
  }
}

.arrow-style {
  bottom: 18px;
}

.mgn-tp {
  margin-top: 1px;
}

.admit-discharge-header {
  color: #132742;
  border-bottom: 1px solid #bfbfbf;
  font: normal normal bold 24px PermianSlabSerifTypeface;
}

@media (min-width: 1098px) {
  .main-content-width {
    width: 1150px;
    margin: auto !important;
  }
}

.nameAddress {

  .form-row>.col,
  .form-row>[class*="col-"] {
    padding-right: 20px;
  }
}

.cityState {

  .form-row>.col,
  .form-row>[class*="col-"] {
    padding-right: 30px;
  }
}

.unbold {
  font: normal normal 18px/27px Open Sans !important;
}

.setWidth {
  max-width: 100px !important;
}

.margin-top-1 {
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .tab-height {
    display: flex;
  }

  .item-height {
    display: flex;
    flex: 1;
  }

  .link-height {
    flex: 1;
  }
}

@media (min-width: 992px) {
  .tab-height {
    display: flex;
  }

  .item-height {
    display: flex;
    flex: 1;
  }

  .link-height {
    flex: 1;
  }
}

@media (max-width: 480px) {
  .modal-dialog {
    margin: 2.5rem !important
  }
}

@media screen and (min-width: 481px) and (max-width: 541px) {
  .modal-dialog {
    margin: 6rem !important
  }
}

@media screen and (max-width: 281px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    min-width: 180px !important;
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    min-width: 180px !important;
  }

  .modal-title {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 282px) and (max-width: 320px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    min-width: 220px !important;
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    min-width: 220px !important;
  }

  .modal-title {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 360px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    min-width: 250px !important;
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    min-width: 250px !important;
  }

  .modal-title {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 379px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    min-width: 255px !important;
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    min-width: 255px !important;
  }

  .modal-title {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 380px) and (max-width: 414px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    min-width: 315px !important;

    &.form-actions-btn {
      min-width: 285px !important;
      max-width: 285px !important;
    }
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    min-width: 315px !important;

    &.form-actions-btn {
      min-width: 285px !important;
      max-width: 285px !important;
    }
  }

  .modal-title {
    font-size: 26px !important;
  }
}

@media screen and (min-width: 415px) and (max-width: 767px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    min-width: 240px !important;
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    min-width: 240px !important;
  }

  button#dialog.btn-primary {
    margin-left: 0px !important;
  }
}

.env-message {
  text-align: center;
  padding: 3px;
  color: #d12a00;
  font-family: 'Open Sans';
  font-size: 18px;
}

@media (min-width: 768px) {

  button#dialog.btn-secondary,
  button#dialog.btn-link .modal-footer .btn {
    &.form-actions-btn {
      min-width: 150px !important;
      max-width: 150px !important;
    }
  }

  button#dialog.btn-primary,
  button#dialog.btn-link {
    &.form-actions-btn {
      min-width: 150px !important;
      max-width: 150px !important;
    }
  }
}

.form-actions {
  max-width: 90%;
  margin: 5px auto;
  gap: 3rem;

  @media (min-width: 768px) {
    margin: 0;
    max-width: 480px;
  }
}

button#myAppeals.btn.btn-block.btn-outline-secondary {
  width: 148px !important;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #132742;
  color: #132742;
  background: #fff;
  font: normal normal bold 18px/15px Open Sans !important;

  &:hover {
    background: #d12a00;
    border: 1px solid #d12a00;
    color: #fff;
  }

  &:active {
    background: #fff !important;
    color: #132742 !important;
  }

  &:disabled {
    background: #fff !important;
    color: #3d3e40 !important;
    border-color: #d1d3d4 !important;
  }
}

@media screen and (max-width: 767px) {

  button#myAppeals.btn.btn-block.btn-outline-secondary,
  button[type=button].btn.btn-link {
    width: 328px !important;
    height: 48px;
    background: #fff;
    margin-left: 1px;

    &:active {
      background: #fff !important;
      border-color: #174a7c !important;
    }

    &:hover {
      background: #fff !important;
      border-color: #132742 !important;
    }
  }
}

.modal {
  overflow-y:auto !important;
}

nh-my-subbmissions {
  .ngx-datatable.bootstrap .datatable-header {
    background: #132742;
  }
}

.radio-buttons-container {
  mat-radio-button {
    .mdc-form-field>label {
      margin-bottom: 0rem;
      font-weight: 400;
      font-family: "Open Sans";
      font-size: 14px;
      margin-left: -8px;
    }
 
    .mdc-form-field {
      .mdc-radio {
        width: 20px;
        height: 17px;
      }
    }
  }
 
  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-disabled-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-focus-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-hover-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-hover-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mat-mdc-radio-ripple-color: rgba(0, 0, 0, 0.54);
    --mat-mdc-radio-checked-ripple-color: rgba(0, 0, 0, 0.54);
  }
}
 
.start-expansion-panel {
  .ngx-datatable.bootstrap {
    .datatable-header {
      background: #174a7c !important;

      .datatable-header-cell {
        padding: 0.2rem;

        .datatable-header-cell-label {
          font-size: 14px;
        }
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0 24px 16px !important;
  }

  .mat-expansion-indicator::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #174a7c;
    content: "";
    vertical-align: unset;
    transform: rotate(180deg);
    padding: unset;
    display: inline-block;
  }

  @media screen and (max-width: 767px) {
    .mat-expansion-indicator {
      position: absolute;
      top: 19%;
      right: 5%;
    }

    .mat-expansion-panel-body {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .button_container {
    justify-content: center;
    margin-left: 0px !important;
    margin-right: 0px !important;

    div {
      margin-right: 0px !important;
    }
  }

  .start-expansion-panel {
    padding-bottom: 10px;
  }
}