// Fonts
@import 'montserrat';
@import 'open-sans';
@import 'font-awesome';

$nh-montserrat: 'Montserrat', sans-serif;

@mixin montserrat($weight: normal) {
  font-family: $nh-montserrat;
  font-weight: $weight;
}

@mixin montserrat-light() {
  @include montserrat(300);
}

@mixin montserrat-bold() {
  @include montserrat(700);
}
