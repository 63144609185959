/* open-sans-300normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Open Sans Light '), local('Open Sans-Light'),
    url('~typeface-open-sans/files/open-sans-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-open-sans/files/open-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-400normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Open Sans Regular '), local('Open Sans-Regular'),
    url('~typeface-open-sans/files/open-sans-latin-400.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-open-sans/files/open-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-600normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Open Sans SemiBold '), local('Open Sans-SemiBold'),
    url('~typeface-open-sans/files/open-sans-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-open-sans/files/open-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-700normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Open Sans Bold '), local('Open Sans-Bold'),
    url('~typeface-open-sans/files/open-sans-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('~typeface-open-sans/files/open-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}
